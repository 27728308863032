import 'vite/modulepreload-polyfill'

export const loadScript = (src, async = true, type = 'text/javascript') => {
  return new Promise((resolve, reject) => {
    try {
      const tag = document.createElement('script');
      const container = document.head || document.body;

      tag.type = type;
      tag.async = async;
      tag.src = src;

      tag.addEventListener('load', () => {
        resolve({ loaded: true, error: false, url: src });
      });

      tag.addEventListener("error", () => {
        reject({
          loaded: false,
          error: true,
          message: `Failed to load script with src ${src}`,
        });
      });

      container.appendChild(tag);
    } catch (error) {
      reject(error);
    }
  });
};

const trapFocusHandlers = {};

export const trapFocus = (container, elementToFocus = container) => {
  var elements = getFocusableElements(container);
  var first = elements[0];
  var last = elements[elements.length - 1];

  removeTrapFocus();

  trapFocusHandlers.focusin = (event) => {
    if (event.target !== container && event.target !== last && event.target !== first) return;

    document.addEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.focusout = function () {
    document.removeEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.keydown = function (event) {
    if (event.code.toUpperCase() !== 'TAB') return; // If not TAB key
    // On the last focusable element and tab forward, focus the first element.
    if (event.target === last && !event.shiftKey) {
      event.preventDefault();
      first.focus();
    }

    //  On the first focusable element and tab backward, focus the last element.
    if ((event.target === container || event.target === first) && event.shiftKey) {
      event.preventDefault();
      last.focus();
    }
  };

  document.addEventListener('focusout', trapFocusHandlers.focusout);
  document.addEventListener('focusin', trapFocusHandlers.focusin);

  elementToFocus.focus();

  if (elementToFocus.tagName === 'INPUT' && ['search', 'text', 'email', 'url'].includes(elementToFocus.type) && elementToFocus.value) {
    elementToFocus.setSelectionRange(0, elementToFocus.value.length);
  }
}

export const fetchProductsInCart = () => {

  let productsInCart = {'regularProducts': [], 'swatchProducts': [], 'swatchProductsLimit': [], 'swatchProductsLimitReached': []};

  return fetch(`${window.routes.cart_url}.js`, { method: 'GET' })
  .then(response => response.json())
  .then((response) => {
    let regularProductsCount = 0;
    let swatchProductsCount = 0;
    let swatchProductsLimit = window.swatches.buyLimit;
    response.items.forEach((item) => {
      let productTypeLowercased = item.product_type.toLowerCase();
      if (productTypeLowercased == window.swatches.productTypeUpholstery || productTypeLowercased == window.swatches.productTypeMaterial) {
        swatchProductsCount = swatchProductsCount + item.quantity;
        productsInCart['swatchProducts'].push(item);
      } else {
        regularProductsCount = regularProductsCount + item.quantity;
        productsInCart['regularProducts'].push(item);
      }
    });
    productsInCart['swatchProductsLimit'].push(swatchProductsLimit);
    if (swatchProductsCount >= swatchProductsLimit) {
      productsInCart['swatchProductsLimitReached'].push(true);
    } else {
      productsInCart['swatchProductsLimitReached'].push(false);
    }
    return productsInCart
  })
  .catch(console.error)
}

function getFocusableElements(container) {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe"
    )
  );
}

function removeTrapFocus(elementToFocus = null) {
  document.removeEventListener('focusin', trapFocusHandlers.focusin);
  document.removeEventListener('focusout', trapFocusHandlers.focusout);
  document.removeEventListener('keydown', trapFocusHandlers.keydown);

  if (elementToFocus) elementToFocus.focus();
}
